import { Observable } from 'rxjs/Observable';
import { Inject, Injectable } from '@angular/core';
import {
  BuildSettings,
  BUILD_SETTINGS,
} from '@core/configuration/tokens/build-settings.token';
import { HttpClient } from '@angular/common/http';
import { PremiumRecalculation } from '../interfaces/policy-premium-recalculation.interface';
import { ClientPremiumRecalculation } from '../interfaces/client-premium-recalculation.interface';
import { ScheduleRecalculation } from '../interfaces/policy-schedule-recalculation.interface';

@Injectable({
  providedIn: 'root',
})
export class PolicyPremiumRecalculationService {
  private premiumRecalculationsBaseURL = `${this.buildSettings.RECALCULATION_API}/v1`;
  constructor(
    private http: HttpClient,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) {}
  
  public getClientPremiumRecalculation(
    query?: GetPremiumRecalculationQuery,
  ): Observable<ClientPremiumRecalculation[]> {
    return this.http.get<ClientPremiumRecalculation[]>(
      this.premiumRecalculationsBaseURL+'/ClientPremiumRecalculation',
      {
        params: {
          ...(query as any),
        },
      },
    );
  }

  public getPolicyPremiumRecalculation(policyNoLong: string,
    query?: GetPolicyPremiumRecalculationQuery,
  ): Observable<PremiumRecalculation> {
    return this.http.get<PremiumRecalculation>(
      this.premiumRecalculationsBaseURL+'/'+policyNoLong,
      {
        params: {
          ...(query as any),
        },
      },
    );
  }

  public getPolicyScheduleRecalculation(policyNoLong: string,
    query?: GetPolicyScheduleRecalculationQuery,
  ): Observable<ScheduleRecalculation> {
    return this.http.get<ScheduleRecalculation>(
      this.premiumRecalculationsBaseURL+'/schedule-recalculation/'+policyNoLong,
      {
        params: {
          ...(query as any),
        },
      },
    );
  }
}

export interface GetPremiumRecalculationQuery {
  uniquePolicyRef?: string;
  factor?: string;
  newValue?: string;
}

export interface GetPolicyPremiumRecalculationQuery {
  dateOfBirth?: string;
  excess?: string;
  gender?: string;
  postcode?: string;
  breed?: string;
  productId?: string;
  backdateToDate?: string;
  transientAdjustment?: number;
}

export interface GetPolicyScheduleRecalculationQuery {
  paymentDay: number;
  earlyPayment?: boolean;
}