import { CoverChangeType } from "@features/change-cover/enums/cover-change-type.enum";
import { CoverProducts } from "@features/change-cover/enums/cover-products.enum";
import { CoverType } from "@features/change-cover/enums/cover-type.enum";
import { LimitType } from "@features/change-cover/enums/limit-type.enum";
import { ProductType } from "@features/change-cover/enums/product-type.enum";
import { ChangeCoverQuote } from "@features/change-cover/interfaces/change-cover-quote";
import { DogTreatment } from "@features/change-cover/interfaces/dog-treatment.interface";
import { Treatment } from "@features/change-cover/interfaces/treatment.interface";
import { CoverItem } from "@interfaces/cover-item";
import { Policy } from "@interfaces/policy";
import { createAction, props } from "@ngrx/store";

const setCoverType = createAction(
  '[Cover Selection] Set Cover Type',
  props<{ coverType: CoverType }>(),
);

const setProductType = createAction(
  '[Cover Selection] Set Product Type',
  props<{ productType: ProductType }>(),
);

const setLimitType = createAction(
  '[Cover Selection] Set Limit Type',
  props<{ limitType: LimitType }>(),
);

const fetchPolicy = createAction(
  '[Cover Selection] Fetch Policy',
  props<{ id: string }>());

const fetchPolicySuccess = createAction(
  '[Cover Selection] Fetch Policy Success',
  props<{ policy: Policy, isMTA: boolean }>(),
);

const fetchPolicyFailure = createAction(
  '[Cover Selection] Fetch Policy Failure');

const setCoverProduct = createAction(
  '[Cover Selection] Set Cover Product',
  props<{ coverProduct: CoverProducts }>(),
);

const setChangeCoverQuote = createAction(
  '[Cover Selection] Set Change Cover Quote',
  props<{ changeCoverQuote: ChangeCoverQuote }>(),
);

const setExcess = createAction(
  '[Cover Selection] Set Excess',
  props<{ excess: number }>(),
);

const setCurrentCover = createAction(
  '[Cover Selection] Set Current Cover',
  props<{ currentCover: CoverItem[] }>(),
);

const fetchChangeCoverQuoteSuccess = createAction(
  '[Cover Selection] Set Possible Cover',
  props<{ possibleCovers: ChangeCoverQuote[], excess: number }>(),
);

const fetchCoverChangeTypeSuccess = createAction(
  '[Cover Selection] Fetch Change Cover Type',
  props<{ coverChangeType: CoverChangeType }>(),
);

const treatmentCostComponentLoaded = createAction(
  '[Review Cover] Treatment Cost Component Loaded',
);

const fetchTreatmentDataForDogSuccess = createAction(
  '[Review Cover] Fetch Treatment Data For Dog Success',
  props<{ treatmentData: DogTreatment[] }>(),
);

const fetchTreatmentDataForCatSuccess = createAction(
  '[Review Cover] Fetch Treatment Data For Cat Success',
  props<{ treatmentData: Treatment[] }>(),
);

const setSelectedCoverFromPolicy = createAction(
  '[Cover Selection] Set Selected Cover From Policy',
);

export const coverSelectionActions = {
  setCoverType,
  setProductType,
  setLimitType,
  fetchPolicy,
  fetchPolicySuccess,
  fetchPolicyFailure,
  setCoverProduct,
  setChangeCoverQuote,
  setExcess,
  setCurrentCover,
  fetchChangeCoverQuoteSuccess,
  fetchCoverChangeTypeSuccess,
  treatmentCostComponentLoaded,
  fetchTreatmentDataForDogSuccess,
  fetchTreatmentDataForCatSuccess,
  setSelectedCoverFromPolicy,
};