import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vet-fees-example',
  templateUrl: './vet-fees-example.component.html',
  styleUrls: ['./vet-fees-example.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VetFeesExampleComponent {
  @Input() isV3orV4: boolean;
  @Input() productName: string;
  constructor(public activeModal: NgbActiveModal) {}
}
