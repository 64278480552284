import { ChangeCoverQuote } from '@features/change-cover/interfaces/change-cover-quote';
import {
  CancellationProviders,
} from '@features/policies/interfaces/cancellation-providers.interface';
import { CancellationReason } from '@features/policies/interfaces/cancellation-reason.interface';
import { DiscountResponse } from '@features/policies/interfaces/discount-response.interface';
import {
  PremiumRecalculation,
} from '@features/policies/interfaces/policy-premium-recalculation.interface';
import { createAction, props } from '@ngrx/store';

const startRenewalJourney = createAction(
  '[Renewal] Start Renewal Journey',
);

const dismissRenewalJourney = createAction(
  '[Renewal] Dismiss Renewal Journey',
);

const completeRenewalJourney = createAction(
  '[Renewal] Complete Renewal Journey',
);

const fetchPossibleCoverSuccess = createAction(
  '[Renewal] Get possible cover',
  props<{ possibleCovers: ChangeCoverQuote[] }>(),
);

const benefitsPageLoaded = createAction(
  '[Renewal] Benefits Page Loaded',
);

const completePageLoaded = createAction(
  '[Renewal] Complete Page Loaded',
);

const excessOptionsPageLoaded = createAction(
  '[Renewal] Excess Options Page Loaded',
);

const paymentSuccess = createAction(
  '[Renewal] Payment Success',
);

const acceptRenewal = createAction(
  '[Renewal] Accept Renewal',
);

const acceptRenewalFailure = createAction(
  '[Renewal] Accept Renewal Failure',
);

const fetchImportantInfoSuccess = createAction(
  '[Renewal] Fetch Important Information Success',
  props<{ changes: string[] }>(),
);

const fetchImportantInfoFailure = createAction(
  '[Renewal] Fetch Important Information Failure',
);

const fetchRenewalSuccess = createAction(
  '[Renewal] Fetch Renewal Success',
  props<{ isConfirmationRequired: boolean }>(),
);

const fetchRenewalFailure = createAction(
  '[Renewal] Fetch Renewal Failure',
);

const discountsPageLoaded = createAction(
  '[Renewal] Discounts Page Loaded',
);

const fetchDiscountSuccess = createAction(
  '[Renewal] Fetch Discount Success',
  props<{ discount: DiscountResponse }>(),
);

const fetchDiscountPriceSuccess = createAction(
  '[Renewal] Fetch Discount Price Success',
  props<{ premiumRecalculation: PremiumRecalculation }>(),
);

const cancelPageLoaded = createAction(
  '[Renewal] Cancel Page Loaded',
);

const acceptRenewalDiscount = createAction(
  '[Renewal] Accept Renewal Discount',
);

const applyRenewalDiscount = createAction(
  '[Renewal] Apply Renewal Discount',
);

const applyRenewalDiscountSuccess = createAction(
  '[Renewal] Apply Renewal Discount Success',
);

const applyRenewalDiscountFailure = createAction(
  '[Renewal] Apply Renewal Discount Failure',
);

const fetchCancellationReasons = createAction(
  '[Renewal] Fetch Cancellation Reasons',
);

const fetchCancellationReasonsSuccess = createAction(
  '[Renewal] Fetch Cancellation Reasons Success',
  props<{ cancellationReasons: CancellationReason[] }>(),
);

const fetchCancellationReasonsFailure = createAction(
  '[Renewal] Fetch Cancellation Reasons Failure',
);

const fetchCancellationProviders = createAction(
  '[Renewal] Fetch Cancellation Providers',
);

const fetchCancellationProvidersSuccess = createAction(
  '[Renewal] Fetch Cancellation Providers Success',
  props<{ cancellationProviders: CancellationProviders[] }>(),
);

const fetchCancellationProvidersFailure = createAction(
  '[Renewal] Fetch Cancellation Providers Failure',
);

export const RenewalActions = {
  startRenewalJourney,
  dismissRenewalJourney,
  completeRenewalJourney,
  fetchPossibleCoverSuccess,
  benefitsPageLoaded,
  completePageLoaded,
  excessOptionsPageLoaded,
  paymentSuccess,
  acceptRenewal,
  acceptRenewalFailure,
  fetchImportantInfoSuccess,
  fetchImportantInfoFailure,
  fetchRenewalSuccess,
  fetchRenewalFailure,
  discountsPageLoaded,
  fetchDiscountSuccess,
  fetchDiscountPriceSuccess,
  cancelPageLoaded,
  applyRenewalDiscount,
  applyRenewalDiscountSuccess,
  applyRenewalDiscountFailure,
  acceptRenewalDiscount,
  fetchCancellationReasons,
  fetchCancellationReasonsSuccess,
  fetchCancellationReasonsFailure,
  fetchCancellationProviders,
  fetchCancellationProvidersSuccess,
  fetchCancellationProvidersFailure,
};
