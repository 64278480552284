import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterModule } from '../../components/footer/footer.module';
import { MainPageComponent } from './main-page.component';
import { 
  MainPageContainerModule,
} from '@core/layout/containers/main-page-container/main-page-container.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FooterModule,
    MainPageContainerModule,
  ],
  declarations: [MainPageComponent],
  exports: [MainPageComponent],
})
export class MainPageModule {}
