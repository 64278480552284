import { OnInit, Component} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { MonitoringService } from './monitoring/services/monitoring.service';
import { Idle, DEFAULT_INTERRUPTSOURCES  } from '@ng-idle/core';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit {
  loggedIn = this.oauthService.hasValidAccessToken();

  constructor(
    protected oauthService: OAuthService,
    // We need to create an instance of the monitoring service here for App Insights
    protected monitoringService: MonitoringService,
    protected idleService: Idle,
  ) {
    // set idle parameters
    // how long can they be inactive before considered idle, in seconds
    idleService.setIdle(10 * 60);
    // how long can they be idle before considered timed out, in seconds
    idleService.setTimeout(5 * 60);
    // provide sources that will "interrupt" aka provide events indicating the user is active
    idleService.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // do something when the user has timed out
    idleService.onTimeout.subscribe(() => {
      this.oauthService.logOut();
    });
  }

  ngOnInit(): void {
    this.idleService.watch();
  }
}
