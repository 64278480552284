import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LimitType } from '@features/change-cover/enums/limit-type.enum';
import { ProductVersion } from '@features/policies/enums/product-version.enum';
import { Policy } from '@interfaces/policy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-copayments-explanation-modal',
  templateUrl: './copayments-explanation-modal.component.html',
  styleUrls: ['./copayments-explanation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopaymentsExplanationModalComponent implements OnInit {
  @Input() policy: Policy;
  @Input() productType: string;
  @Input() productVersion: ProductVersion;
  @Input() limit: number;
  @Input() limitType: LimitType;

  amountRemaining: number;
  exampleClaimAmount = 800;
  exampleTotalYouPay = 239.20;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.productVersion === ProductVersion.V3 || this.productVersion === ProductVersion.V4) {
      this.amountRemaining = this.limit - this.exampleClaimAmount + this.exampleTotalYouPay;
    } else {
      this.amountRemaining = this.limit - this.exampleClaimAmount;
    }
  }
}
