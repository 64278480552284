<div class="p-3 p-lg-4">
  <div class="row">
    <div class="col d-flex justify-content-end">
      <button class="btn btn-link p-0" (click)="activeModal.close('Close click')" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.CLOSE</button>
    </div>
  </div>
  <h3 class="vet-fees-example-heading" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.HEADING</h3>
  <p class="mb-4" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.SUMMARY</p>
  <div class="row mb-3">
    <div class="col fw-bold" translate>AUTH.POLICY.CHANGEOFCOVER.REVIEW.LOWCOVERPRODUCT.EXAMPLE.MODAL.CLAIMEXAMPLEHEADING</div>
  </div>
  <ng-container *ngIf="isV3orV4; else vetFeesExampleV2">
    <app-vet-fees-example-v3-content [productName]="productName"></app-vet-fees-example-v3-content>
  </ng-container>
  <ng-template #vetFeesExampleV2>
    <app-vet-fees-example-v2-content></app-vet-fees-example-v2-content>
  </ng-template>
</div>