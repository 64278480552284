import { createReducer, on } from "@ngrx/store";
import { RenewalState, initialRenewalState } from "../renewal.state";
import { RenewalActions } from "../actions/renewal.actions";
import { coreActions } from "@core/store/actions/core.actions";
import { changeCoverActions } from "../actions/change-cover.actions";

export const renewalReducer = createReducer(
  initialRenewalState,
  on(RenewalActions.startRenewalJourney, (state): RenewalState => ({
    ...state,
    usingJourney: true,
    journeyComplete: false,
  })),

  on(RenewalActions.dismissRenewalJourney, (state): RenewalState => ({
    ...state,
    dismissed: true,
  })),

  on(RenewalActions.completeRenewalJourney, (state): RenewalState => ({
    ...state,
    dismissed: true,
  })),

  on(changeCoverActions.resetState, (state): RenewalState => ({
    ...state,
    usingJourney: false,
  })),

  on(RenewalActions.completePageLoaded, (state): RenewalState => ({
    ...state,
    journeyComplete: true,
  })),

  on(RenewalActions.fetchImportantInfoSuccess, (state, { changes }): RenewalState => ({
    ...state,
    changes,
  })),

  on(RenewalActions.fetchImportantInfoFailure, (state): RenewalState => ({
    ...state,
    changes: [],
  })),

  on(RenewalActions.fetchRenewalSuccess, (state, { isConfirmationRequired }): RenewalState => ({
    ...state,
    isConfirmationRequired,
  })),


  on(
    RenewalActions.discountsPageLoaded,
    RenewalActions.cancelPageLoaded,
    (state): RenewalState => ({
      ...state,
      discount: null,
    }),
  ),

  on(RenewalActions.fetchDiscountSuccess, (state, { discount }): RenewalState => ({
    ...state,
    discount,
  })),

  on(
    RenewalActions.acceptRenewalDiscount,
    (state): RenewalState => ({
      ...state,
      discountAccepted: true,
    }),
  ),

  on(
    RenewalActions.fetchCancellationReasonsSuccess,
    (state, { cancellationReasons }): RenewalState => ({
      ...state,
      cancellationReasons,
    }),
  ),

  on(
    RenewalActions.fetchCancellationProvidersSuccess,
    (state, { cancellationProviders }): RenewalState => ({
      ...state,
      cancellationProviders,
    }),
  ),

  on(
    RenewalActions.applyRenewalDiscount,
    (state): RenewalState => ({
      ...state,
      isApplyDiscountInProgress: true,
    }),
  ),

  on(
    RenewalActions.applyRenewalDiscountFailure,
    (state): RenewalState => ({
      ...state,
      isApplyDiscountInProgress: false,
    }),
  ),

  on(
    RenewalActions.applyRenewalDiscountSuccess,
    (state): RenewalState => ({
      ...state,
      isApplyDiscountInProgress: false,
    }),
  ),

  on(coreActions.resetStore,
    (): RenewalState => ({
      ...initialRenewalState,
    })),
);
