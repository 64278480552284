import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@core/layout/components/header/header.module';
import { NavbarContainerModule } from '../navbar-container/navbar-container.module';
import { MainPageContainerComponent } from './main-page-container.component';



@NgModule({
  declarations: [MainPageContainerComponent],
  imports: [
    CommonModule,
    HeaderModule,
    RouterModule,
    NavbarContainerModule,
  ],
  exports: [MainPageContainerComponent],
})
export class MainPageContainerModule { }
