import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { coverSelectionActions } from "../actions/cover-selection.actions";
import { filter, map, switchMap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { CoverSelectionSelectors } from "../selectors/cover-selection.selector";
import { ChangeCoverQuote } from "@features/change-cover/interfaces/change-cover-quote";
import { ChangeCoverService } from "@features/change-cover/services/change-cover.service";
import { changeCoverActions } from "../actions/change-cover.actions";
import { PolicySelectors } from "@features/policies/store/policy.selectors";
import { RenewalActions } from "../actions/renewal.actions";

@Injectable()
export class CoverSelectionEffects {
  fetchChangeCoverQuotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeCoverActions.startChangeCoverJourney, RenewalActions.startRenewalJourney),
      concatLatestFrom(() =>
        this.store.select(PolicySelectors.selectPolicy),
      ),
      switchMap(([_, policy]) =>
        this.changeCoverService
          .getChangeCoverQuotes(policy.policyUniqueRef)
          .pipe(
            map((quotes: ChangeCoverQuote[]) =>
              coverSelectionActions.fetchChangeCoverQuoteSuccess({
                possibleCovers: quotes,
                excess: policy.excess,
              }),
            ),
          ),
      ),
    );
  });

  fetchCoverChangeType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        changeCoverActions.startChangeCoverJourney,
        coverSelectionActions.setChangeCoverQuote,
      ),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicy),
        this.store.select(CoverSelectionSelectors.selectChangeCoverQuote),
      ]),
      filter(
        ([_, policy, changeCoverQuote]) =>
          policy !== null && changeCoverQuote != null,
      ),
      map(([_, policy, changeCoverQuote]) => {
        const coverChangeType = this.changeCoverService.getCoverChangeType(
          policy,
          changeCoverQuote,
        );
        return coverSelectionActions.fetchCoverChangeTypeSuccess({
          coverChangeType,
        });
      }),
    );
  });

  loadTreatmentDataForDog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(coverSelectionActions.treatmentCostComponentLoaded),
      switchMap(() =>
        this.changeCoverService.getTreatmentDataForDog().pipe(
          map((treatmentData) =>
            coverSelectionActions.fetchTreatmentDataForDogSuccess({
              treatmentData,
            }),
          ),
        ),
      ),
    );
  },
  );

  loadTreatmentDataForCat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(coverSelectionActions.treatmentCostComponentLoaded),
      switchMap(() =>
        this.changeCoverService.getTreatmentDataForCat().pipe(
          map((treatmentData) =>
            coverSelectionActions.fetchTreatmentDataForCatSuccess({
              treatmentData,
            }),
          ),
        ),
      ),
    );
  },
  );

  setSelectedCoverFromPolicy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        coverSelectionActions.setSelectedCoverFromPolicy,
      ),
      concatLatestFrom(() => [
        this.store.select(CoverSelectionSelectors.selectPolicyQuote),
        this.store.select(CoverSelectionSelectors.selectChangeCoverQuote),
      ]),
      filter(
        ([_, policyQuote, currentQuote]) =>
          policyQuote != null && currentQuote == null,
      ),
      map(([_, policyQuote]) =>
        coverSelectionActions.setChangeCoverQuote({ changeCoverQuote: policyQuote }),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private changeCoverService: ChangeCoverService,
  ) { }
}