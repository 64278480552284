import { PolicyHolder } from '@interfaces/policyholder';
import { AddressSearchResponse } from '@shared/interfaces/address-search-response.interface';
import { ClientDetailsEnum } from '../enums/client-details.enum';
import { PhoneEditStates } from '../enums/phone-edit-states.enum';
import { AddressEditState } from '../enums/address-edit-state.enum';
import { PolicyName } from '../interfaces/policy-name.interface';
import { RetrieveAddressResult } from '../components/Interfaces/address-retrieve-result.interface';
import {
  ClientPremiumRecalculation,
} from '@features/policies/interfaces/client-premium-recalculation.interface';
import { ClientRestrictions } from '../interfaces/client-restrictions.interface';
import { ClientConsents } from '../interfaces/client-consents.interface';

export const PolicyHolderStateName = 'Policyholder';

export interface PolicyHolderState {
  policyHolder: PolicyHolder;
  searchAddress: AddressSearchResponse[];
  retrieveAddress: RetrieveAddressResult;
  hasAnyActivePolicy: boolean | null;
  hasActiveLiveAnnualPolicy: boolean | null;
  hasActiveHorseRiderPolicy: boolean | null;
  hasActiveLimitedAccessPolicy: boolean | null;
  hasMultipleActiveMonthlyPolicies: boolean | null;
  petNames: PolicyName[];
  editing: ClientDetailsEnum | null;
  editingStatus: PhoneEditStates | AddressEditState | null,
  premiumRecalculation: ClientPremiumRecalculation[] | null,
  isJoiiRegistered: boolean | null;
  restrictions: ClientRestrictions,
  consents: ClientConsents,
}

export const initialPolicyHolderState: PolicyHolderState = {
  policyHolder: null,
  searchAddress: null,
  retrieveAddress: null,
  hasAnyActivePolicy: null,
  hasActiveLiveAnnualPolicy: null,
  hasActiveHorseRiderPolicy: null,
  hasActiveLimitedAccessPolicy: null,
  hasMultipleActiveMonthlyPolicies: null,
  petNames: [],
  editing: null,
  editingStatus: null,
  premiumRecalculation: null,
  isJoiiRegistered: null,
  restrictions: null,
  consents: null,
};
